import { Env } from "@stencil/core";

export const getBundleUrl = (bundleName: string) => {
	const bucketBundles = JSON.parse(Env.firebaseConfig).storageBundlesBucket;
	const bundleUrl = `https://storage.googleapis.com/${bucketBundles}/${bundleName}.bundle`;
	return bundleUrl;
};

export function addDays(date: Date, days: number): Date {
	const newDate = new Date(date);
	newDate.setDate(newDate.getDate() + days);
	return newDate;
}

export function addMonths(date: Date, months: number): Date {
	const newDate = new Date(date);
	newDate.setMonth(newDate.getMonth() + months);
	return newDate;
}

export function generateNumericId(length = 9): string {
	return (Date.now() % 10 ** length).toString().padStart(length, "0");
}
