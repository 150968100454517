export interface InvoiceCar {
	vin: string;
	model: string;
	amount: number;
}
export interface AGInvoiceCar extends InvoiceCar {
	container: string;
	services: SelectServices;
}

export type InvoiceCars = InvoiceCar[];
export type AGInvoiceCars = AGInvoiceCar[];

export enum SelectServices {
	DELIVERY = "Delivery",
	STORAGE = "Storage",
	INSURANCE = "Insurance",
	PRICE = "Vehicle purchase",
	ENTRY_EXIT = "In and out fee charges",
	SERVICES = "AGT services",
}
